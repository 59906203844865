<template>
	<div v-if="order">
		<p>订单号：{{order.no}}</p>
		<p>商品金额：￥{{order.goods_price/100}}</p>
		<p>优惠金额：￥{{order.discount_price/100}}</p>
		<p>配送金额：￥{{order.freight_price/100}}</p>
		<p>实付金额：￥{{order.paid_price/100}}</p>
		<template v-if="order.address">
			<p>联系：{{order.mobile}} {{order.linkman}}</p>
			<p>地区：{{order.regions_name.join(' ')}}</p>
			<p>地址：{{order.address}}</p>
		</template>
		<div style="margin-top: 10px;">
			<el-button size="mini" type="primary" @click="drawer = true" :disabled="!$utils.update('orders') || $utils.sendOrder(order.status)">发货</el-button>
			<el-button size="mini" type="info" :disabled="!$utils.update('orders') || $utils.editOrder(order.status)">修改</el-button>
			<el-button size="mini" type="danger" :disabled="!$utils.update('orders') || $utils.closeOrder(order.status)">关闭</el-button>
			<el-button size="mini" type="warning" :disabled="!$utils.update('orders') || $utils.deleteOrder(order.status)">删除</el-button>
		</div>

		<el-drawer :title="order.no+'订单商品清单'" direction="rtl" size="50%" :visible.sync="drawer" @close="drawer = false">
			<div style="padding: 0 20px;">
				<el-table :size="theme.size" :data="order.order_goods" id="exportPdf" ref="exportPdf">
					<el-table-column label="首图" width="60">
						<el-image slot-scope="scope" :src="scope.row.img" :preview-src-list="[scope.row.img]"><i slot="error" class="el-icon-picture-outline"></i></el-image>
					</el-table-column>
					<el-table-column label="名称" prop="title"/>
					<el-table-column label="规格" prop="goods_attr_desc" width="120"/>
					<el-table-column label="货号" prop="no" width="100"/>
					<el-table-column label="单价" prop="shop_price" width="80">
						<template slot-scope="scope">{{(scope.row.shop_price/100).toFixed(2)}}</template>
					</el-table-column>
					<el-table-column label="数量" prop="quantity" width="80"/>
					<el-table-column label="小计" width="80">
						<template slot-scope="scope">{{((scope.row.shop_price * scope.row.quantity)/100).toFixed(2)}}</template>
					</el-table-column>
					<el-table-column label="待配送" prop="pending" width="160">
						<el-input-number slot-scope="scope" controls-position="right" v-model="scope.row.pending" :min="0" :max="scope.row.quantity - scope.row.sended" :size="theme.size" :step="1" step-strictly></el-input-number>
					</el-table-column>
					<div slot="append" style="padding: 10px 0;">
						<el-button type="primary" icon="el-icon-printer" :size="theme.size" @click="onPrint(order)">打印配货清单</el-button>
						<el-button type="primary" icon="el-icon-s-promotion" :size="theme.size" @click="createDelivery(order)" v-if="order.address" :disabled="!$utils.create('deliveries')">创建交付订单</el-button>
						<el-button type="primary" icon="el-icon-s-promotion" :size="theme.size" @click="onSend(order)" v-if="!order.address && order.status == 'payed'">确认发货</el-button>
					</div>
				</el-table>
			</div>
		</el-drawer>
	</div>
	<div v-else>正在加载订单详情...</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		name: 'MsgCradOrder',
		props: {
			id: Number 
		},
		methods: {
			async getOrder (id) {
				const res = await this.$http.get(this.$api.URI_ORDERS, {params: {action: 'show', id: id}});
				this.order = res.data.result;
			}
		},
		computed: {
			...mapState(['theme'])
		},
		mounted () {
			this.getOrder(this.id);
		},
		data() {
			return {
				order: null,
				drawer: false,
			}
		}
	}
</script>