import { render, staticRenderFns } from "./imessage.vue?vue&type=template&id=fcba4674&"
import script from "./imessage.vue?vue&type=script&lang=js&"
export * from "./imessage.vue?vue&type=script&lang=js&"
import style0 from "./imessage.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports