<template>
	<div class="pc-main" style="padding: 0;">
		<div class="wrap">
			<div class="left" ref="session-body">
				<div class="user" v-for="(session, s) in sessions" :key="s">
					<div>
						<el-avatar shape="square" :src="session.avatar"></el-avatar>
					</div>
					<div class="desc">
						<div class="head">
							<div class="name">{{session.name}}</div>
							<div class="at">{{session.at}}</div>
						</div>
						<div class="foot">
							<div class="text">{{session.content}}</div>
							<el-badge :max="99" :hidden="!session.noread" :value="session.noread"></el-badge>
						</div>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="head">
					<div class="who">
						<div style="font-size: 20px; font-weight: 600;">ADer</div>
					</div>
					<div class="btns">
						<i class="el-icon-setting"></i>
					</div>
				</div>
				<div class="body" ref="message-body">
					<div :class="['message',s%2?'current':'']" v-for="(message, s) in messages" :key="s">
						<el-avatar class="avatar" shape="square" :src="message.avatar"></el-avatar>
						<div class="name">{{message.name}}</div>
						<div class="content" v-if="message.template == 'text'">{{message.content.text}}</div>
						<div class="content link" @click="$utils.openWindow($config.ROOTPATH+'goods/'+message.content.id)" v-if="message.template == 'goods'">
							<div class="content-flex">
								<img width="80" :src="message.content.image" alt="">
								<div class="desc">
									<div class="titel">{{message.content.title}}</div>
									<div class="mate">金额：{{message.content.shop_price/100}}元</div>
								</div>
							</div>
						</div>
						<div class="content" v-if="message.template == 'order'">
							<card-order :id="message.content.id"></card-order>
						</div>
					</div>
				</div>
				<div class="foot">
					<div class="tools">
						<div class="l">
							<i class="el-icon-picture" style="margin-right: 10px;"></i>
							<i class="el-icon-camera-solid"></i>
						</div>
						<div class="r">
							<i class="el-icon-s-promotion" @click="send"></i>
						</div>
					</div>
					<div style="padding: 0 20px 20px;">
						<el-input type="textarea" maxlength="30" resize="none" placeholder="请输入内容" v-model="textarea" :rows="3" show-word-limit></el-input>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import Socket from '@/utils/socket';
	import cardOrder from '@/components/MsgCardOrder';

	export default {
		components: {
			cardOrder,
		},
		computed: {
			...mapState(['theme']),
			height () {
				return (this.theme.height - 180)
			}
		},
		methods: {
			toTop (e) {
				var warp = this.$refs[e];
				warp.scrollTop = 0;
			},
			toBottom (e) {
				var warp = this.$refs[e];
				warp.scrollTop = warp.scrollHeight;
			},
			send () {
				this.toTop('session-body');
				this.toBottom('message-body');
				if (!this.textarea) return false;
				Socket.onSend({msg: this.textarea});
				this.textarea = '';
			}
		},
		data() {
			return {
				sessions: [
					{
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						noread: 0,
						content: '就看见卡拉斯科就看见卡拉斯科就看见卡拉斯科'
					}, {
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						noread: 1,
						content: '就看见卡拉斯科就看见卡拉斯科就看见卡拉斯科'
					}, {
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						noread: 234,
						content: '就看见卡拉斯科就看见卡拉斯科就看见卡拉斯科'
					}
				],
				messages: [
					{
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						former: 0,
						template: 'text',
						content: {text: '就看见卡拉斯科就看见卡拉斯科就看见卡拉斯科'}
					}, {
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						former: 0,
						template: 'goods',
						content: {
							id: 1,
							title: '就看见卡拉斯科就看见卡拉斯科就看见卡拉斯科',
							image: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
							shop_price: 3464534
						}
					}, {
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						former: 0,
						template: 'order',
						content: {id: 8, no: 'O202212373287832'}
					}, {
						name: 'ADer',
						avatar: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
						at: '3-19 12:32',
						former: 0,
						template: 'order',
						content: {id: 12, no: 'O202212373287832'}
					}
				],
				textarea: ''
			}
		},
		created () {
			// 离开路由之后断开websocket连接
			// Socket.onSend({msg: '离开路由之后断开websocket连接'});
		}
	};
</script>

<style>
	.wrap {
		width: 100%;
		height: 100%;
		display: flex;
	}
	.wrap .left {
		width: 280px;
		padding: 10px 0;
		overflow-y: auto;
		border-right: solid 1px #f1f1f1;
	}
	.wrap .left .user {
		display: flex;
		cursor: pointer;
		align-items: center;
		border-bottom: 1px solid #f1f1f1;
	}
	.wrap .left .user .desc {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
	}
	.wrap .left .user .head {
		display: flex;
		font-size: 16px;
		justify-content: space-between;
	}
	.wrap .left .user .at {
		color: #666;
		font-size: 14px;
	}
	.wrap .left .user .foot {
		color: #666;
		display: flex;
		font-size: 14px;
		align-items: center;
		justify-content: space-between;
	}
	.wrap .left .user .text {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.wrap .right {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.wrap .right .head {
		display: flex;
		line-height: 2;
		padding: 0 15px;
		align-items: center;
		justify-content: space-between;
		border-bottom: solid 1px #f1f1f1;
	}
	.wrap .right .body {
		flex: 1;
		overflow-y: auto;
		background: #f1f1f1;
		box-sizing: border-box;
	}
	.wrap .right .body .message {
		padding: 10px 60px 0;
		position: relative;
	}
	.wrap .right .body .message.current {
		text-align: right;
	}
	.wrap .right .body .message.current .avatar {
		left: unset;
		right: 10px;
	}
	.wrap .right .body .message .avatar {
		position: absolute;
		top: 10px;
		left: 10px;
	}
	.wrap .right .body .message .content {
		padding: 10px;
		background: #fff;
		text-align: left;
		border-radius: 5px;
		display: inline-block;
	}
	.wrap .right .body .message .content.link {
		cursor: pointer;
	}
	.content-flex {
		display: flex;
	}
	.content-flex .desc {
		margin-left: 10px;
	}
	.wrap .right .foot {
		border-top: solid 1px #f1f1f1;
	}
	.wrap .right .tools {
		display: flex;
		font-size: 20px;
		padding: 5px 20px;
		align-items: center;
		justify-content: space-between;
	}
</style>

