import Store from '@/store'
import Cache from '@/utils/cache'

let Socket;

const createSocket = (uri = "ws://sw.dajing.ren/ws") => {
	Socket && Socket.close();
	if (!Socket && Store.state.hasLogin) {
		Socket = new WebSocket(uri + '?token=' + Cache.get("access_token", false));
		Socket.onmessage = onMessage;
		Socket.onopen = onOpen;
		Socket.onerror = onError;
		Socket.onclose = onClose;
	}
}

const onMessage = (e) => {
	console.log(JSON.parse(e.data));
}

const onOpen = () => {
	// console.log(Store.state.admininfo);
	// onSend({cmd: 'init_ws', user_id: Store.state.admininfo.id});
}

const onSend = (d) => {
	!Socket && Socket.createSocket();
	Socket.send(JSON.stringify(d));
}

const onError = () => {
	Socket.createSocket();
}

const onClose = (e) => {
	console.log('断开连接', e);
}

export default {
	createSocket,
	onSend
}
